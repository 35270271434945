import React from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { hook, bindings } from '@vl/redata';
import useRoute from '@vl/hooks/useGbRoute';

export default bindings({
  formContainer: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              // get actionCode from input url
              const route = useRoute();
              const routeParams = _.get(route, 'params', {});
              // effect to update username from actionCode

              const form = useFormik({
                initialValues: {
                  actionCode: routeParams.actionCode,
                  username: '',
                  password: '',
                  passwordConfirm: '',
                },
                onSubmit: async (values, actions) => {
                  try {
                    const res = await ctx.apply('authModel.resetPassword', values.actionCode, values.password);
                    form.setStatus({
                      success: {
                        message: res.message,
                      },
                    });
                    // redirect on success
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },
                validationSchema: Yup.object().shape({
                  actionCode: Yup.string().required(ctx.apply('i18n.t', 'Form.required')),
                  username: Yup.string()
                    .email('Invalid email')
                    .required(ctx.apply('i18n.t', 'Form.required')),
                  password: Yup.string().required(ctx.apply('i18n.t', 'Form.required')),
                  passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
                }),
              });

              React.useEffect(() => {
                (async () => {
                  try {
                    const username = await ctx.apply('authModel.verifyResetCode', routeParams.actionCode);
                    form.setFieldValue('username', username);
                  } catch (err) {
                    form.setStatus({
                      error: {
                        message: err.message,
                      },
                    });
                  }
                })();
                // eslint-disable-next-line
              }, [routeParams.actionCode, form]);

              return form;
            }),
            canSubmit: hook((ctx) => {
              return ctx.get('form.isValid') && !ctx.get('form.isSubmitting');
            }),
          },
        },
      ],
    ],
  },
});
