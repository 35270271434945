import React from 'react';
import {
  Button, Row, Col, Alert
} from 'antd';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import FormProvider from '@uz/unitz-components-web/FormProvider';
import {
  Input, Form, FormItem
} from 'formik-antd';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

export default () => (
  <DIV className="formContainer">
    <div>{ctx.apply('i18n.t', 'Profile.resetPassword')}</div>
    <FormProvider form={ctx.get('form')}>
      <Form {...layout} className="login-form" id="login-form">
        <Row>
          <Col className="formStatus" span={24}>
            {!!ctx.get('form.status.error') && (
              <div className="py-2">
                <Alert message={ctx.get('form.status.error.message')} type="error" />
              </div>
            )}
            {!!ctx.get('form.status.success') && (
              <div className="py-2">
                <Alert message={ctx.get('form.status.success.message')} type="success" />
              </div>
            )}
          </Col>
        </Row>
        <FormItem disabled name="username" label={ctx.apply('i18n.t', 'Login.email')}>
          <Input name="username" disabled placeholder={ctx.apply('i18n.t', 'Signup.email')} />
        </FormItem>
        <FormItem name="password" label={ctx.apply('i18n.t', 'Signup.password')}>
          <Input.Password name="password" placeholder={ctx.apply('i18n.t', 'Signup.password')} />
        </FormItem>
        <FormItem name="passwordConfirm" label={ctx.apply('i18n.t', 'Signup.passwordConfirm')}>
          <Input.Password name="passwordConfirm" placeholder={ctx.apply('i18n.t', 'Signup.passwordConfirm')} />
        </FormItem>
        <Row>
          <Col {...tailLayout.wrapperCol}>
            <Button
              type="primary"
              htmlType="submit"
              id="submit"
              disabled={!ctx.get('canSubmit')}
              loading={ctx.get('form.isSubmitting')}
            >
              <span id="button-text">{ctx.apply('i18n.t', 'Profile.send')}</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  </DIV>
);
